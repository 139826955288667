import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    setSingleEmployeePayrollDetails,
    setAllEmployeesGeneratePayrollDetails,
    setMaonthlyPaymentDetails_ByPayrollMasterId,
    setAllPayrollMaster,
    setSingleEmployeePayrollCTC_ById,
    setAllEmployeesPayrollsCTC,
    startSingleEmployeePayrollDetails,
    startAllEmployeesGeneratePayrollDetails,
    startMaonthlyPaymentDetails_ByPayrollMasterId,
    startAllPayrollMaster,
    startSingleEmployeePayrollCTC_ById,
    startAllEmployeesPayrollsCTC,
    startSingleEmployeeDraftSalary,
    successOfAddEdit,
    setErrorOfAddEdit,
    startAddEmployeePayrollCTC,
    startAddSinglePayrollDetail,
    startAddMsterId,
    startAddEditPaymentStatus,
    startDeleteEmployeePayrollCTC,
    startSingleEmployeeAllPayrollCTC_EmployeeId,
    setSingleEmployeeAllCTCPayroll_ByEmployeeId,
    completedFetch,
    setMasterId
} from './payrollDataSlice.Selector';
//
///API's
import {
    allEmployeesPayrollsData,
    singleEmployeePayrollsData,
    addEmployeePayroll,
    deleteEmployeePayrollsData,
    allPayrollMasterData,
    singlePayrollMasterData,
    generateNewPayrollDetailsData,
    singleEmployeePayrrollDetail,
    addSinglePayrollDetail,
    addPayrollMasterData,
    addEmployeePaymentStatus,
    singleEmployeeAllPayrollsByEmployeeId,
    singleEmployeeDraftSalary,
} from '../../Api/PayRollsEmployeeAPI/payrollsEmployeeAPI';
import { newAPI } from '../../Api/newAxios';
import { setNotificationAction } from '../NotificationStore/notification.slice.selector';
////////////////
const addDeductionsPayrollsCTC = async (data) => {
    let result;
    for (let i = 0; i < data.length; i++) {
        result = await newAPI.post('employeePayrollsDeduction/add', data[i]);
        if (data.length - 1 === i) {
            return result;
        }
    }

    return result;
};
const addDeductionsPayrollDetails = async (data) => {
    let result;
    for (let i = 0; i < data.length; i++) {
        result = await newAPI.post('payrollDetailDeductions/add', data[i]);

        if (data.length - 1 === i) {
            return result;
        }
    }

    return result;
};
////(1) Watcher for All Details
function* start_startAllEmployeesPayrollsCTC() {
    yield takeLatest(startAllEmployeesPayrollsCTC.type, set_setAllEmployeesPayrollsCTC);
}
//(1) Next
function* set_setAllEmployeesPayrollsCTC() {
    try {
        const data = yield call(allEmployeesPayrollsData);
        yield put(setAllEmployeesPayrollsCTC(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(2)Watcher
function* start_startSingleEmployeePayrollCTC_ById() {
    yield takeLatest(startSingleEmployeePayrollCTC_ById.type, set_setSingleEmployeePayrollCTC_ById);
}
//(2)Next
function* set_setSingleEmployeePayrollCTC_ById({ payload }) {
    try {
        const data = yield call(singleEmployeePayrollsData, payload);
        yield put(setSingleEmployeePayrollCTC_ById(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        yield put(setErrorOfAddEdit(error.response.data));
    }
}
//(3)
function* start_startAllPayrollMaster() {
    yield takeLatest(startAllPayrollMaster.type, set_setAllPayrollMaster);
}
//(3) Next
function* set_setAllPayrollMaster({ payload }) {
    try {
        let data = yield call(allPayrollMasterData);

        yield put(setAllPayrollMaster(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(4)
function* start_startMaonthlyPaymentDetails_ByPayrollMasterId() {
    yield takeLatest(startMaonthlyPaymentDetails_ByPayrollMasterId.type, set_setMaonthlyPaymentDetails_ByPayrollMasterId);
}
//(4) Next
function* set_setMaonthlyPaymentDetails_ByPayrollMasterId({ payload }) {
    try {
        const data = yield call(singlePayrollMasterData, payload);
        yield put(setMaonthlyPaymentDetails_ByPayrollMasterId(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//(5)Delete Watcher
function* startDelete_startDeleteEmployeePayrollCTC() {
    yield takeLatest(startDeleteEmployeePayrollCTC.type, set_CTC_Delete);
}

//(5) Next
function* set_CTC_Delete({ payload }) {
    const { employee_id, added_by, remarks } = payload;
    let data = {
        employee_id,
        annual_ctc: null,
        basic: null,
        hra: null,
        special_allowance: null,
        tds: null,
        gross_pay: null,
        status: 1,
        added_by,
        remarks
    };
    try {
        const addBeforeDelete = yield call(addEmployeePayroll, data);
        const resp = yield call(deleteEmployeePayrollsData, payload.id);
        yield put(successOfAddEdit());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(6)Add CTC For Employee Watcher
function* startAdd_startAddEmployeePayrollCTC() {
    yield takeLatest(startAddEmployeePayrollCTC.type, set_CTC_Add);
}
//(6) Next
export function* set_CTC_Add({ payload }) {
    const { deductionDataArr, payrollData } = payload;
    try {
        if (deductionDataArr[0]?.id && deductionDataArr[0].id) {
            yield call(addEmployeePayroll, payrollData);
            yield call(addDeductionsPayrollsCTC, deductionDataArr);
        } else {
            const resp = yield call(addEmployeePayroll, payrollData);

            const ctc_payroll_id = resp.data.insertId;

            let deductionFullDataArray = deductionDataArr.map((item) => {
                return {
                    ctc_payroll_id,
                    ...item
                };
            });

            yield call(addDeductionsPayrollsCTC, deductionFullDataArray);
        }

        yield put(successOfAddEdit());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(7)Next
function* start_startAllEmployeesGeneratePayrollDetails() {
    yield takeLatest(startAllEmployeesGeneratePayrollDetails.type, setstartAllEmployeesGenerate);
}
//(7) Next
function* setstartAllEmployeesGenerate() {
    try {
        const data = yield call(generateNewPayrollDetailsData);
        yield put(setAllEmployeesGeneratePayrollDetails(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        yield put(setErrorOfAddEdit(error.response.data));
    }
}
//(8)watcher
function* start_startSingleEmployeePayrollDetails() {
    yield takeLatest(startSingleEmployeePayrollDetails.type, setStartSingleEmployeePayrollDetails);
}
//(8) Next
function* setStartSingleEmployeePayrollDetails({ payload }) {
    try {
        const data = yield call(singleEmployeePayrrollDetail, payload);
        yield put(setSingleEmployeePayrollDetails(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        yield put(setErrorOfAddEdit(error.response.data));
    }
}
//(9)Add Payroll Details For Employee Watcher
function* startAdd_startAddSinglePayrollDetail() {
    yield takeLatest(startAddSinglePayrollDetail.type, set_Payroll_Add);
}
//(9) Next
function* set_Payroll_Add({ payload }) {
    const { deductionDataArr, payrollDetailData } = payload;

    try {
        const resp = yield call(addSinglePayrollDetail, payrollDetailData);
        yield call(addDeductionsPayrollDetails, deductionDataArr);
        yield put(successOfAddEdit());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(10)Add CTC For Employee Watcher
function* startAdd_startAddMsterId() {
    yield takeLatest(startAddMsterId.type, set_startAddMsterId);
}
//(10) Next
function* set_startAddMsterId({ payload }) {
    try {
        if (Boolean(payload?.id)) {
            yield call(addPayrollMasterData, payload);
        } else {
            const resp = yield call(addPayrollMasterData, payload);
            yield put(setMasterId(resp.data.insertId));
        }
        yield put(successOfAddEdit());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(11)Add Payroll Payment Status
function* startAdd_startAddEditPaymentStatus() {
    yield takeLatest(startAddEditPaymentStatus.type, set_startAddEditPaymentStatus);
}
//(11) Next
function* set_startAddEditPaymentStatus({ payload }) {
    const { payrollDetailsData, paymentData } = payload;
    try {
        const resp = yield call(addEmployeePaymentStatus, paymentData);

        if (payrollDetailsData.payment_id !== null) {
        } else {
            const result = yield call(addSinglePayrollDetail, {
                ...payrollDetailsData,
                paid_status: 1,
                payment_id: resp.data.insertId
            });
        }
        yield put(successOfAddEdit());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(12)GEt Employee CTC'S List By EMployee Id
function* start_startSingleEmployeeAllPayrollCTC_EmployeeId() {
    yield takeLatest(startSingleEmployeeAllPayrollCTC_EmployeeId.type, set_setSingleEmployeeAllCTCPayroll_ByEmployeeId);
}
//(12) Next
function* set_setSingleEmployeeAllCTCPayroll_ByEmployeeId({ payload }) {
    try {
        const resp = yield call(singleEmployeeAllPayrollsByEmployeeId, payload);
        yield 
        yield put(setSingleEmployeeAllCTCPayroll_ByEmployeeId(resp));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(13)Draft Salary Detail By EMployee Id
function* start_startSingleEmployeeDraftSalary() {
    yield takeLatest(startSingleEmployeeDraftSalary.type, set_setSingleEmployeePayrollDetailsl_ByEmployeeId);
}
//(13) Next
function* set_setSingleEmployeePayrollDetailsl_ByEmployeeId({ payload }) {
    try {
        const data = yield call(singleEmployeeDraftSalary, payload);
        yield put(setSingleEmployeePayrollDetails(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
///Accumulator
export default function* ParollSaga() {
    yield all([
        call(start_startSingleEmployeePayrollCTC_ById),
        call(start_startAllPayrollMaster),
        call(start_startAllEmployeesPayrollsCTC),
        call(start_startMaonthlyPaymentDetails_ByPayrollMasterId),
        call(startDelete_startDeleteEmployeePayrollCTC),
        call(startAdd_startAddEmployeePayrollCTC),
        call(start_startAllEmployeesGeneratePayrollDetails),
        call(start_startSingleEmployeePayrollDetails),
        call(startAdd_startAddSinglePayrollDetail),
        call(startAdd_startAddMsterId),
        call(startAdd_startAddEditPaymentStatus),
        call(start_startSingleEmployeeAllPayrollCTC_EmployeeId),
        call(start_startSingleEmployeeDraftSalary)
    ]);
}
